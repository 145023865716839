'use client'

import { cn } from '@/utils/cn'
import { cva } from 'class-variance-authority'
import Link from 'next/link'
import { usePathname } from 'next/navigation'

const tabVariants = cva('text-center text-xl font-bold leading-[25px]', {
  variants: {
    status: {
      active: 'border-b-2 border-black text-black',
      inactive: 'border-b border-neutral text-muted',
    },
  },
  defaultVariants: {
    status: 'inactive',
  },
})

type TabProps = {
  icon?: React.ReactNode
  className?: string
  label: string
  path: string
  id: string
}

export const Tab = ({
  label,
  className,
  path,
  id,
}: TabProps): React.ReactNode => {
  const pathname = usePathname()
  const active = pathname.includes(path)
  return (
    <Link
      className={cn(
        tabVariants({ status: active ? 'active' : 'inactive' }),
        className,
        'block h-full w-full px-4 pb-4',
      )}
      id={id}
      href={`/customer/login/${path}`}
    >
      {label}
    </Link>
  )
}
